import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import Phone from '../../../components/Phone'
import Email from '../../../components/Email'
import {PARTICIPATION_STATUS_WAITING, PARTICIPATION_STATUSES} from '../../../config/constants'
import { getFullName } from '../../../utils/functions'
import actions from '../../../redux/actions'

const propTypes = {
  gender: PropTypes.oneOf([0, 1]), // TODO: resolve the problem with import constants (them are imported as undefined),
  filterStatuses: PropTypes.array,
}

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
})

class MeetingParticipantsTable extends Component {
  render () {
    const {
      meeting: {
        participants,
      },
      gender,
      filterStatuses,
      lang,
      dispatch,
    } = this.props

    const participantsByGender = participants && participants.filter(p => (
      p.gender === +gender
      && (!filterStatuses || filterStatuses.indexOf(p.status) !== -1)
    ))

    const styles = {
        tableResponsive: {
          overflowX: 'auto',
        },
        td: {
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        },
      };

    return (
      <>
        <div style={styles.tableResponsive}>
          <Table>
            <thead>
            <tr>
              <th>{lang.full_name}</th>
              <th style={{ width: '160px' }}>{lang.phone}</th>
              <th>{lang.email}</th>
              <th>{lang.age_range}</th>
              <th>{lang.city}</th>
              <th>{lang.status}</th>
            </tr>
            </thead>
            <tbody>
            {
              participantsByGender && !!participantsByGender.length &&
              participantsByGender.map(participant => (
                <tr key={participant.participant_id}>
                  <td style={styles.td}>
                    <Button color="link"
                            className="px-0"
                            onClick={() => dispatch(
                              actions.participants.openModalForm(
                                participant.participant_id, true))}>
                      {getFullName(participant)}
                    </Button>
                  </td>
                  <td>

                    <Phone isVerified={participant.is_phone_verified}>
                      {participant.phone}</Phone>
                  </td>
                  <td>
                    <Email isVerified={participant.is_email_verified}>
                     {participant.email}</Email>
                  </td>
                  <td>
                    {
                      (participant.age_range) && (participant.age_range.title)
                    }
                  </td>
                  <td style={styles.td}>
                    {
                      (participant.city) && (participant.city.name)
                    }
                  </td>
                  <td style={styles.td}>
                    {participant.is_waiting_list_message_sent
                        ? lang.waiting_list_message_sent
                        : lang[PARTICIPATION_STATUSES[participant.status]]}
                  </td>
                </tr>
              ))
            }

            {
              (!participantsByGender || participantsByGender.length === 0) &&
              (
                <tr>
                  <td colSpan={5} className="text-center text-muted">
                    {lang.empty}
                  </td>
                </tr>
              )
            }
            </tbody>
          </Table>
        </div>
      </>
    )
  }
}

MeetingParticipantsTable.propTypes = propTypes

export default withLang(connect(mapStateToProps)(MeetingParticipantsTable))